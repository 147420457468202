// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-theme-musician-src-templates-landing-js": () => import("/opt/build/repo/node_modules/gatsby-theme-musician/src/templates/landing.js" /* webpackChunkName: "component---node-modules-gatsby-theme-musician-src-templates-landing-js" */),
  "component---node-modules-gatsby-theme-musician-src-pages-404-js": () => import("/opt/build/repo/node_modules/gatsby-theme-musician/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-musician-src-pages-404-js" */),
  "component---src-pages-legal-datenschutz-js": () => import("/opt/build/repo/src/pages/legal/datenschutz.js" /* webpackChunkName: "component---src-pages-legal-datenschutz-js" */),
  "component---src-pages-legal-impressum-js": () => import("/opt/build/repo/src/pages/legal/impressum.js" /* webpackChunkName: "component---src-pages-legal-impressum-js" */)
}

